<!-- 个人资料 -->
<template>
    <div class="personalData">
        <div class="personal">
            个人资料
        </div>
        <div class="" style="display: flex;flex: 1;flex-direction: column;">
            <div class="" style="width: 100%;display: flex;justify-content: space-around;">
                <div class="" style="flex: 1;">
                    <el-form ref="form" :model="form" :rules="rules" class="form" inline label-width="120px">
                        <el-form-item label="姓名" prop="name">
                            <el-input v-model="form.name" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号码" prop="IDcard">
                            <el-input v-model="form.IDcard" placeholder="请输入身份证号" ></el-input>
                        </el-form-item>
                        <el-form-item label="性别" prop="sex">
                            <div class="sex" >
                                <el-radio-group v-model="form.sex">
                                    <el-radio :label="1">男</el-radio>
                                    <el-radio :label="2">女</el-radio>
                                </el-radio-group>
                            </div>
                        </el-form-item>
                        <el-form-item label="出生日期" prop="birthday">
                            <el-date-picker v-model="form.birthday" value-format="yyyy-MM-dd" 
                            type="date" placeholder="请选择出生日期">
                            </el-date-picker>
                        </el-form-item>
                        <!-- <el-form-item label="用户名">
                            <el-input v-model="form.userName" placeholder="" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item label="家长姓名">
                            <el-input v-model="form.parentName" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="phoneNumber">
                            <el-input v-model="form.phoneNumber" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="民族" prop="nation">
                            <el-input v-model="form.nation" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="籍贯" prop="nativePlace">
                            <el-input v-model="form.nativePlace" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="地址" prop="address">
                            <el-input v-model="form.address" placeholder=""></el-input>
                        </el-form-item>
                        <div class="" style="margin:10px 70px 0px 50px;">
                            <span style="color: #666;margin-bottom: 7px;display: inline-block;">照片</span>
                            <div class="" style="font-size: 12px;color: red;margin-bottom: 10px;">
                                *上传照片格式要求 413×551px（jpeg，jpg）
                            </div>
                            <el-upload class="upload-demo" drag :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload" :show-file-list="false"
                                action="https://artxt.szart.cn/api/public/index.php/api/common/upload">
                                <img v-if="this.fullurl!==''" :src="this.fullurl" class="avatar">
                                <i class="el-icon-upload" v-else></i>
                                <div class="el-upload__text" v-if="this.fullurl==''">将文件拖到此处，或
                                    <span style="color: red;">
                                        点击上传
                                    </span>
                                </div>
                                <div class="el-upload__tip" v-if="this.fullurl==''">只能上传jpg/jpeg文件，且不超过1M</div>
                            </el-upload>
                        </div>
                    </el-form>
                </div>
                <!-- 照片 -->
                
            </div>
            <el-divider></el-divider>
            <!-- 底部 提交与取消 -->
            <div slot="footer" class="dialog-footer">
                <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')" >提 交</el-button>
                <el-button class="btn" @click="resetForm('form')">取 消</el-button>
            </div>
        </div>
        <el-divider></el-divider>
        <div class="buttom">
            <div class="">
                &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
            </div>
            <div class="">
                服务热线：0755-8883 0206
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    name: "", //姓名
                    IDcard: "", //身份证号码
                    sex:1, //性别
                    birthday: "", //出生日期
                    // userName: "", //用户名
                    parentName: "", //家长姓名
                    phoneNumber: "", //手机号码
                    nation: "汉族", //民族
                    nativePlace: "中国", //籍贯
                    address: "", //地址
                    id: "" //id
                },
                birthday: "", //出生日期
                rules: {
                    name: [{
                        required: true,
                        message: '姓名不能为空',
                        trigger: 'blur'
                    }, ],
                    IDcard: [{
                            required: true,
                            message: '身份证号码不能为空',
                            trigger: 'blur'
                        },
                    ],
                    nation: [{
                        required: true,
                        message: '民族不能为空',
                        trigger: 'blur'
                    }, ],
                    nativePlace: [{
                        required: true,
                        message: '国籍不能为空',
                        trigger: 'blur'
                    }, ],
                    sex: [{
                        required: true,
                        message: '性别不能为空',
                        trigger: 'blur'
                    }, ],
                    birthday: [{
                        required: true,
                        message: '出生日期不能为空',
                        trigger: 'blur'
                    }, ],
                    phoneNumber: [{
                        required: true,
                        message: '手机号不能为空',
                        trigger: 'blur'
                    }, ],
                },
                imageUrl: null,
                bool:false,
                fullurl:"",//显示图片   
                url:""//传的图片
            }
        },
        async created() {
			await this.$request({url: '/api/studentexam/info', method: 'POST', data: {
				uid: localStorage.getItem('token')
			}}).then(res => {
				console.log(res)
				this.info = res.data
			})
            this.form.name = this.info.username
            this.form.IDcard = this.info.idcard
            this.form.sex = this.info.gender
            // if (this.form.sex != 2) {
            //     this.form.sex = "男"
            // } else {
            //     this.form.sex = "女"
            // }
            this.form.birthday = this.info.birthdate
            // this.form.userName=this.info
            this.form.parentName = this.info.parent_name
            this.form.phoneNumber = this.info.mobile1
            this.form.nation = this.info.nation
            this.form.nativePlace = this.info.nation2
            this.form.address = this.info.address
            this.form.id = this.info.id
            this.fullurl=this.info.head_image
        },
        methods: {
            // 上传图片
            handleAvatarSuccess(res, file) {
                console.log(res, file)
                this.imageUrl = res.data;
                this.fullurl=this.imageUrl.fullurl
                this.url=this.imageUrl.url
            },
            beforeAvatarUpload(file) {
                console.log(file.type)
                const isJPG = file.type === 'image/jpg';
                const isJPEG = file.type === 'image/jpeg';
                const isPNG = file.type === 'image/png';
                const isLt1M = file.size / 1024 / 1024 < 1;

                let flag = [isJPG, isJPEG].includes(true)

                if (!flag) {
                    this.$message.error('上传头像图片只能是 JPG/JPEG 格式!');
                }
                if (!isLt1M) {
                    this.$message.error('上传头像图片大小不能超过 1MB!');
                }

                return flag && isLt1M;
            },
            submitForm(form) { //提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        this.$request({
                            url: '/api/studentexam/infoEdit',
                            method: 'POST',
                            data: {
                               username:this.form.name,
                               idcard:this.form.IDcard,
                               gender:this.form.sex,
                               birthdate:this.form.birthday,
                               parent_name:this.form.parentName,
                               mobile1:this.form.phoneNumber,
                               nation:this.form.nation,
                               nation2:this.form.nativePlace,
                               address:this.form.address,
                               head_image:this.url,
                               id:this.form.id,
							   uid: localStorage.getItem('token')
                            }
                        }).then(res=>{
                            console.log(res)
                            if(res.code == 1) {
								this.$message({
								  message: '修改成功',
								  type: 'success'
								});
                                this.$router.push({
                                    path: "/dashboard"
                                })
							}else{
                                this.$message({
                                  message: res.msg,
                                  type: 'error'
                                });
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                // this.$refs[form].resetFields();
                this.$router.push({
                    path: "/dashboard"
                })
            },
            Losefocus() { //失去焦点赋值生日性别
                // 赋值生日
                let IDcard = this.form.IDcard
                if (IDcard.trim().length == 18) {
                    // console.log(IDcard.slice(6,10)+'-'+IDcard.slice(10,12)+'-'+IDcard.slice(12,14))
                    this.form.birthday = IDcard.slice(6, 10) + '-' + IDcard.slice(10, 12) + '-' + IDcard.slice(12, 14)
                }

                // 赋值性别
                let sex = IDcard.slice(16, 17)
                console.log(sex)
                if (sex) {
                    if (sex % 2 !== 0) {
                        this.form.sex = "男" //男
                        this.sex=1;
                    } else {
                        this.form.sex = "女" //女
                        this.sex=2;
                    }
                }
            },
        }
    }
</script>

<style scoped="scoped">
    .personalData {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .personal {
        padding: 25px;
        font-size: 16px;
        font-weight: bold;
    }

    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 340px;
        height: 37px !important;
    }
    .sex{
        width: 340px;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #fff;
        color: #606266;
    }
    @media screen and (max-width:768px){
        .el-form {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        .form ::v-deep .el-form-item__label{
            width: 110px !important;
        }
        .form ::v-deep .el-input__inner{
            padding-right: 10px !important;
            width: 170px !important;
            height: 35px !important;
        }
        .el-input {
            width: 170px !important;
            height: 35px !important;
        }
        .sex{
            width: 120px !important;
        }
    }
/* 性别选择按钮 */
    .form ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;

    }

    .form ::v-deep.el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }

    .form ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 上传图片 */
    ::v-deep .el-upload-dragger {
        width: 173px;
        height: 211px;
        position: relative;
    }

    .el-upload-dragger .el-icon-upload {
        margin-top: 25px;
    }

    ::v-deep .el-upload__text,
    .el-upload__tip {
        font-size: 12px;
        width: 108px;
        margin: 8px auto;
    }

    .avatar {
        width: 173px;
        height: 211px;
    }

   

    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        margin-top: 30px;
    }

    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }

    /* 底部 */
    .buttom {
        height: 30px;
        line-height: 30px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
